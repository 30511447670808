import React, { useEffect } from 'react';

const GoogleTagManager = () => {
    useEffect(() => {
        const googleTagManagerId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

        if (googleTagManagerId) {
            const scriptTag = document.createElement('script');
            scriptTag.async = true;
            scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${googleTagManagerId}`;
            document.head.appendChild(scriptTag);

            scriptTag.onload = () => {
                window.dataLayer = window.dataLayer || [];
                function gtag() { dataLayer.push(arguments); }
                gtag('js', new Date());
                gtag('config', googleTagManagerId);
            };
        }
    }, []);

    return null;
};

export default GoogleTagManager;
