import Begin from "./TarotReader/Begin.tsx";

const TarotReader = () => {
    return (
        <div style={{ backgroundColor: '#05014a', minHeight: '100vh', width: '100%', margin: 0 }}>
            <Begin/>
        </div>
    )
}

export default TarotReader;