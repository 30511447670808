// src/components/Footer.tsx
import './Footer.css'
import React from 'react';

const Footer: React.FC = () => {
    return (
        <footer style={{ textAlign: 'center', padding: '10px', fontSize: '14px', backgroundColor: '#f0f0f0', marginTop: '20px' }}>
            © {new Date().getFullYear()} absurd.dev. All Rights Reserved.
        </footer>
    );
};

export default Footer;
