import {CardData} from '../cardData/interfaces'
import fetchCardData from '../cardData/fetchCardData.js'
import './Explaination.css'
import {useEffect, useState} from 'react';

import { Card, Button, Overlay } from 'react-bootstrap';
import TarotCard from "./TarotCard.tsx";


function Explanation (props: any) {

  const [title,setTitle] = useState(props.descriptions[0].title);
  const [description,setDescription] = useState(props.descriptions[0].description);
  const [cardDescription,setCardDescription] = useState("");
  const [currentCard,setCurrentCard] = useState(0);
  const [imageUrl,setImageUrl] = useState("");

  useEffect(() => {
    if (currentCard !== props.atCard) {
      setCurrentCard(props.atCard);
      setTitle(props.descriptions[props.atCard].title);
      setDescription(props.descriptions[props.atCard].description)

      const card = props.cards[props.atCard-1];
      const summarySplit = props.deck[card].summary.split('\n');
      const formattedDescription = summarySplit.map((line:string, index:number) => (
        <p key={index} style={{ backgroundColor: "black", color: "silver" }}>{line}</p>
      ));
      setCardDescription(formattedDescription);
      setImageUrl(props.deck[card].image_url);
      props.setShowOverlay(true);
    }
  }, [props.atCard]);

  const handleClick = () => {
    props.setShowOverlay(!props.showOverlay);
  };


  return (
      <div>
        {props.showOverlay && (
            <div className="overlay">
              <Card className="bg-black">
                <div className="row">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-between align-items-start">
                      <Card.Header className={'tarot-font-roboto'} style={{ color: 'silver' }}>{title} : {description}</Card.Header>
                      <Button className={'tarot-font-roboto btn-dark'} onClick={handleClick}>Close</Button>
                    </div>
                  </div>
                </div>
                <div className="row d-flex ">
                  <div className="col-sm-12 col-md-4 d-flex align-items-center" >
                    <div className='w-100 d-flex justify-content-center'>
                      <TarotCard key={`explanation-tarot-card`} number={props.atCard} descriptions={props.descriptions} backCard={props.backCard} cards={props.cards} deck={props.deck} setShowOverlay={props.setShowOverlay} setCard={props.setCard} flipped={true}/>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8">
                    <Card.Body>
                      <div className="card-text-scroll" style={{ color: 'silver' }}>
                        <div className={"tarot-font-roboto"}>
                          {cardDescription && <div>{cardDescription}</div>}
                        </div>
                      </div>
                    </Card.Body>
                  </div>
                </div>
              </Card>
            </div>
        )}
      </div>
  )
}

export default Explanation;
