
import 'bootstrap/dist/css/bootstrap.min.css';
import './TarotCard.css';


import { CardData } from '../cardData/interfaces';
import fetchCardData  from '../cardData/fetchCardData.js';
import { useState, useEffect } from 'react';

const TarotCard = (props: any) => {

  const [displayImage, setDisplayImage] = useState(props.backCard);
  const [title, setTitle] = useState(props.number as string);
  const [isHidden, setIsHidden] = useState(true);
  const [hidePosition, setHidePosition] = useState(false)

  const handleCardClick = () => {
    const card = props.cards[props.number-1];
    setDisplayImage(props.deck[card].image_url);
    setTitle(props.deck[card].name);
    setIsHidden(false)
    props.setCard(props.number);

    if (!hidePosition) {
        props.setShowOverlay(true);
    }
  }

  useEffect(() => {
      if (props.flipped) {
          setHidePosition(true);
          handleCardClick();
      }
  },[props.flipped])

  return (
      <div>
        <div className={`card ${hidePosition ? 'tarot-card-overlay' : 'tarot-card' } tarot-card-${props.number}`} onClick={handleCardClick}>
          <div className={`img-container`}>
            <img className={`${isHidden ? 'card-back': 'card-front'}`} src={displayImage} />
          </div>
          {!isHidden ? (
              <div className="card-name d-flex text-center">
                <b className={"tarot-font-montserrat"}>{title}</b>
              </div>
          ) :( <div></div>)}
        </div>
          {hidePosition ? (
              <div></div>
          ) : (
              <div className="small tarot-font-montserrat mt-2" style={{ color: 'gold' }}>
                  {props.number} - {props.descriptions[props.number].title}
              </div>)}
      </div>
  )

}

export default TarotCard;
