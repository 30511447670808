import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Carousel from 'react-bootstrap/Carousel';
import {Card, Col, Container, Row} from "react-bootstrap";
import TimeAgo from "./TimeAgo.tsx";

interface Story {
    id: number;
    title: string;
    image_url: string;
    genre: string;
    created_at: string;
}

const StoryList = () => {
    const [stories, setStories] = useState<Story[]>([]);
    const navigate = useNavigate(); // Initialize useNavigate
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const defaultImage = '/logo.png'


    console.log(baseURL);

    useEffect(() => {
        const fetchStories = async () => {
            const response = await fetch(`${baseURL}/api/stories`);
            const data = await response.json();
            setStories(data);
        };

        fetchStories();
    }, []); // Added props.settings.storyTellerUrl as a dependency

    // Function to handle story selection
    const handleSelectStory = (id: number) => {
        // Navigate to the Write component with the selected story's ID
        navigate(`/story/${id}`);
    };

    if (!stories) {
        return (<div>Loading...</div>)
    }

    const formatGenre = (genre) => {
        if (!genre) return 'Genre not specified';

        // First, split the genre if it contains descriptions (splitting at " - ")
        // Then replace underscores with spaces and capitalize each word
        return genre.split(" - ")[0]  // Splitting at " - " and taking the first part
            .replace(/_/g, ' ')  // Replacing all underscores with spaces
            .split(' ')  // Splitting the string into words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalizing the first letter of each word
            .join(' ');  // Joining words back into a single string
    };

    return (
        <Container fluid="md">
            <Row className="gy-4">
                {[...stories].reverse().map((story) => (
                    <Col md={4} key={story.id} className="d-flex align-items-stretch">
                        <Card onClick={() => handleSelectStory(story.id)} className="w-100">
                            <Card.Body className="d-flex flex-column text-center">
                                <Card.Img
                                    variant="top"
                                    src={story.image_url ? `${baseURL}/${story.image_url}` : defaultImage}
                                    style={{ height: '256px', width: '100%', objectFit: 'cover' }}
                                />
                                <Card.Title className="pt-3">{story.title}</Card.Title>
                                <div className="mt-auto">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>{story.genre ? formatGenre(story.genre) : ''}</div>
                                        <div>
                                            Created <TimeAgo timestamp={story.created_at} />
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default StoryList;
