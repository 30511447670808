import React, { useState, useEffect } from 'react';

interface Post {
    id: number;
    date: string;
    guid: { rendered: string };
    modified: string;
    slug: string;
    status: string;
    type: string;
    link: string;
    title: { rendered: string };
    content: { rendered: string; protected: boolean };
    excerpt: { rendered: string; protected: boolean };
    author: number;
    featured_media: number;
    comment_status: string;
    ping_status: string;
    sticky: boolean;
    template: string;
    format: string;
    meta: { footnotes: string };
    categories: number[];
    tags: number[];
    _links: {
        self: { href: string }[];
        collection: { href: string }[];
    };
    imageUrl?: string; // New property for image URL
}

const Musings: React.FC = () => {
    const [posts, setPosts] = useState<Post[]>([]);

    useEffect(() => {
        const fetchPosts = async () => {
            const url = 'https://absurd.dev/blog/wp-json/wp/v2/posts';
            const response = await fetch(url);
            const data = await response.json();
            const postsWithImages = data.map(post => ({
                ...post,
                imageUrl: post.content.rendered.match(/<img.*?src=["'](.*?)["']/)?.[1] // Extract the first image src
            }));
            setPosts(postsWithImages);
        };

        fetchPosts();
    }, []);

    const createMarkup = (html: string) => {
        return { __html: html };
    };

    return (
        <div>
            {posts.map((post, index) => (
                <div key={post.id} className="post-container row mb-3">
                    <h4 className="text-start"><a href={post.link} className="h4">{post.title.rendered}</a></h4>
                    <div className="col-md-2 col-sm-12 text-center">
                        {post.imageUrl && <img src={post.imageUrl} alt="Post image" className="img-fluid me-3"/>}
                    </div>
                    <div className="col-md-10 col-sm-12">
                        <p dangerouslySetInnerHTML={createMarkup(post.excerpt.rendered)}/>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Musings;
