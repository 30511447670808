
function fetchCardData (card_id:number,position:number): Promise<CardData> {

  return new Promise((resolve, reject) => {
    let url = `https://aitarotreader.com/card/${card_id}`;

    if (position != null) {
      url += `?position=${position}`
    }

    fetch (url).then(response => {
      return response.json();
    }).then(data => {
      resolve(data);
    }).catch(error => {
      reject(error);
    })
  })
}

export default fetchCardData;
