import React from "react";

const About = () => {
    return(
        <div className={"container"} >
            <div className={"row"}>
                <h1 className={"text-center"}>About
                    <img src={"/logo.png"} alt="Absurd.dev logo"
                         className="float-center me-3 m-2"
                         style={{width: '32px', height: 'auto'}}/>
                </h1>
                <div className={"col col-2"}/>
                <div className={"col col-8"}>
                    <div className={"text-start"}>
                        <h2>What Is the Point?</h2>
                        <img src={"/static/sisyphus.png"} alt="Stylized Sisyphus" className="img-thumbnail float-start me-3" style={{ width: '200px', height: 'auto' }}/>
                        <p>In a world swiftly transformed by artificial intelligence, many wonder about the role of human creativity and purpose. We find profound joy and meaning in the seemingly mundane...</p>
                    </div>
                </div>
                <div className={"col col-2"}/>
            </div>
        </div>
    )
}
export default About;