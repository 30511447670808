// Import css files for slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Story from "./Story.tsx";;

const StorySlider = ({ }) => {

    const [stories, setStories] = useState<Story[]>([]);
    const navigate = useNavigate(); // Initialize useNavigate
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const defaultImage = '/logo.png'

    console.log(baseURL);

    useEffect(() => {
        const fetchStories = async () => {
            const response = await fetch(`${baseURL}/api/stories`);
            const data = await response.json();
            setStories(data);
        };

        fetchStories();
    }, []); // Added props.settings.storyTellerUrl as a dependency

    // Function to handle story selection
    const handleSelectStory = (id: number) => {
        // Navigate to the Write component with the selected story's ID
        navigate(`/story/${id}`);
    };

    if (!stories) {
        return (<div>Loading...</div>)
    }


    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    return (
        <div style={{ width: "100%", alignContent: "center", justifyContent: "center" }}>
            <Slider {...settings}>
                {stories.map(story => (
                    <div key={story.id} onClick={() => handleSelectStory(story.id)}>
                        <img src={story.image_url ? `${baseURL}/${story.image_url}` : defaultImage}
                             alt={story.title} style={{ width: "100%", height: "auto" }} />
                        <p className={"text-center"}>{story.title}</p>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default StorySlider;
