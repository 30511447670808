import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Start from "./Components/Start.tsx";
import Blocks from "./Components/Blocks.tsx"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from "./Components/Navigation.tsx";
import About from "./Components/About.tsx"
import Lander from "./Components/Lander.tsx";
import Musings from "./Components/Musings.tsx";
import Footer from "./Components/Footer.tsx";
import GoogleTagManager from './Components/GoogleTagManager.tsx';
import TarotReader from "./Components/TarotReader.tsx";
import StoryList from "./Components/StoryList.tsx";
import Story from "./Components/Story.tsx";

function App() {
  return (
        <div className={"App"}>
            <GoogleTagManager/>
            <Router>
                <Navigation/>
                <Routes>
                    <Route path={"/"} element={<Start/>} />
                    <Route path={"/TarotReader"} element={<TarotReader/>} />
                    <Route path={"/musings"} element={<Musings/>} />
                    <Route path={"/stories"} element={<StoryList />} />
                    <Route path={"/scribits"} element={<StoryList />} />
                    <Route path={"/story/:storyId"} element={<Story />} />
                    <Route path={"/about"} element={<About/>}/>
                </Routes>
            </Router>
            <Footer/>
        </div>
  );
}

export default App;
