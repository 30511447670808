import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import "./Navigation.css"

const Navigation = () => {
    return (
        <Navbar expand="lg" className={"navigation-header"}>
            <Container>
                <Navbar.Brand className={"navigation-link"} href="https://absurd.dev"><img src={"/logo192.png"} className="App-logo m-2" alt="logo" style={{ width: '32px', height: 'auto' }} />absurd.dev</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav navigation-link" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link className={"navigation-link"} href={"/"}>Home</Nav.Link>
                        <Nav.Link className={"navigation-link"} href={"/TarotReader"}>Tarot Reader</Nav.Link>
                        <Nav.Link className={"navigation-link"} href={"/stories"}>Scribits</Nav.Link>
                        <Nav.Link className={"navigation-link"} href={"https://absurd.dev/blog"}>Musings</Nav.Link>
                        <Nav.Link className={"navigation-link"} href={"/about"}>About</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation