import 'bootstrap/dist/css/bootstrap.min.css';
import "../App.css"
import React from "react";
import Musings from "./Musings.tsx";
import SmallSlider from "./StorySlider";
import StorySlider from "./StorySlider.tsx";
import {Card} from "react-bootstrap";


const Start = () => {
    return (
        <div className={"App"}>
            <div className={"container mt-3"}>
                <div className={"row text-start justify-content-center"}>
                    <div className={"col-md-2 d-none d-md-block"}/>
                    <div className={"col-md-8 col-sm-12"}></div>
                    <h4><em>Exploring the Intersections of Technology, Creativity, and Existence</em></h4>

                    <p>Each project on absurd.dev is a reflection of life’s inherent contradictions and the human
                        endeavor to find meaning in the seemingly meaningless. Every creation is an invitation to wonder
                        deeper existential questions, wrapped in engaging and often whimsical digital experiences.</p>

                    <div>
                        <div className={"row mb-4"}>
                            <div className={"col-md-2 col-sm-12 justify-content-center text-center"}>
                                <StorySlider/>
                            </div>
                            <div className={"col-md-8 col-sm-12"}>
                                <p className={"align-text-bottom"}><a href={"/scribits"}><b>Scribits: </b></a>Short
                                    stories with each narrative exploring different facets of human experience,
                                    questioning the boundaries between creator and creation, reality and fiction.</p>

                                <p className={"mt-3"}><a href={"/TarotReader"}><b>Tarot Reader: </b></a> An futuristic
                                    tarot card reader that generates insightful readings based on algorithmic
                                    interpretations. This project explores the randomness of fate and the human desire
                                    to find signs and significance, even when none may exist.</p>


                            </div>
                            <div className={"col-md-2 col-sm-12"}>
                                <Card className={"justify-content-center text-center w-200"}>

                                <a href={"/TarotReader"}>
                                    <img src={"/static/tarotcard.png"} alt="A Taort Card"
                                         className="img-fluid me-4"
                                         style={{width: 'auto', height: '100%', background: 'transparent'}}/>
                                </a>

                                </Card>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={"row mt-4 text-start justify-content-center"}>
                    <div className={"col-md-12 col-sm-12"}>
                        <h3><a href={"https://absurd.dev/blog"} className={"h3"}><b>Musing</b></a></h3>
                        <p> Dive into the contemplative depths to
                            unravel the enigmatic tapestry of the absurd. Reflect and examine the quirks of existence,
                            the allure of the surreal, and the intersection of technology and existential philosophy.
                            Muse on the curious and often paradoxical nature of life, and explore a world where meaning
                            and meaninglessness coexist beautifully.</p>
                        <Musings/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Start